import React from "react"
import { Link } from "gatsby"
import pageContentListStyle from "../components/pageContentList.module.css"
import Tags from "../components/tags"
import MaterialIcon from "./materialIcon"

export default props => {
  return (
    <li className={pageContentListStyle.article_list_item}>
      <div className={pageContentListStyle.article_list_item_summary}>
        <div className={pageContentListStyle.article_list_item_info}>
          <Tags tags={props.tags} />
          <div className={pageContentListStyle.article_list_item_time}>
            <MaterialIcon iconName="calendar_today" text="" />
            <time>{props.date}</time>
          </div>
        </div>
        {/* <div>
              <img className={pageContentListStyle.picture} alt="" src={`news.png`} />
            </div> */}
        <div className={pageContentListStyle.article_list_item_title}>
          <Link
            to={props.isTraining ? "/training/" + props.path : props.path}
            className={pageContentListStyle.article_list_link}
          >
            {props.title}
          </Link>
        </div>
      </div>
    </li>
  )
}
