import React from "react"
import pageHeadingStyle from "../components/pageHeading.module.css"
import pageContentListStyle from "../components/pageContentList.module.css"
import ArticleListItem from "../components/articleListItem"
import classNames from "classnames"

export default () => (
  <article>
    <div className={pageHeadingStyle.header}>
      <h2 className={pageHeadingStyle.header_title}>最新情報</h2>
      <p className={pageHeadingStyle.header_description}>
        サイボウズのアクセシビリティに関する最新ニュースをご紹介します
      </p>
    </div>
    <ol
      className={classNames(
        pageContentListStyle.article_list,
        pageContentListStyle.article
      )}
    >
      <ArticleListItem
        path="/news/2020-09-30-visst"
        title="サイボウズ「みんなに伝わるWebサイト制作講座」を11⽉より提供開始"
        tags={["最新情報"]}
        date="2020/09/30"
      />
      <ArticleListItem
        path="/products/2020-05-14-office"
        title="サイボウズOfficeのトップページをスクリーンリーダーで操作しやすくしました"
        tags={["サイボウズ Office", "製品アクセシビリティ情報"]}
        date="2020/05/14"
      />
      <ArticleListItem
        path="/training/presentation-slide-archives"
        title="サイボウズのアクセシビリティ勉強会の資料を公開しました"
        tags={["アクセシビリティ啓発活動"]}
        date="2020/05/14"
      />
      <ArticleListItem
        path="/training/seminar-slide-archives"
        title="サイボウズが外部セミナーで発表した資料を公開しました"
        tags={["アクセシビリティ啓発活動"]}
        date="2020/05/14"
      />
      <ArticleListItem
        path="/404"
        title="【Coming soon】動画：サイボウズ開発運用研修 アクセシビリティ"
        tags={["アクセシビリティ啓発活動"]}
        date="2020/05/14"
      />
    </ol>
  </article>
)
